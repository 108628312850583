import React, { useEffect, useState } from "react";
import Select from "../../components/Select/Select";
import {Row, Col, Input, Card, Textarea, Upload, Button, Message} from "../../components";
import {Formik} from "formik";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
import {useOuvidoriaFormSchema} from "./OuvidoriaFormSchema";

const listaIdentificacao = [
    { label: "Aberta", value: "aberto" },
    { label: "Sigilosa", value: "sigiloso" },
    { label: "Anônima", value: "anonimo" },
];

const listaTipo = [
    { 'label': 'Sugestão', 'value': 'Sugestão' },
    { 'label': 'Reclamação', 'value': 'Reclamação' },
    { 'label': 'Informação', 'value': 'Informação' },
    { 'label': 'Denúncias', 'value': 'Denúncias' },
    { 'label': 'Elogios', 'value': 'Elogios' },
    { 'label': 'COVID-19', 'value': 'Covid' },
    { 'label': 'Assédio Moral/Sexual', 'value': 'Assédio' },
]

const FormularioCurso = () => {

    const { criarOuvidoria } = useRequisicaoSite();
    const {ouvidoriaFormInicial, ouvidoriaFormSchema } = useOuvidoriaFormSchema();
    const [mensagem , setMensagem] = useState<any>({
        type: '',
        msg: ''
    })

    return (
        <Formik
            initialValues={ouvidoriaFormInicial}
            onSubmit={async (values, {setErrors}) => {

                const formData = new FormData();
                formData.append('identificacao', values.identificacao);
                formData.append('tipo', values.tipo);
                formData.append('nome', values.nome);
                formData.append('email', values.email);
                formData.append('cpf', values.cpf);
                formData.append('rg', values.rg);
                formData.append('telefone', values.telefone);
                formData.append('cep', values.cep);
                formData.append('cidade', values.cidade);
                formData.append('bairro', values.bairro);
                formData.append('endereco', values.endereco);
                formData.append('solicitacao', values.solicitacao);

                await criarOuvidoria(formData).then(res => {
                    if (res.data.sucesso) {
                        setMensagem({
                            mensagem: 'Ouvidoria cadastrada com sucesso! Seu protocolo é ' + res.data.ouvidoria.protocolo + '.',
                            type: 'success'
                        });
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }
                }).catch(error => {
                    console.error('Erro na requisição:', error.response.data.error.message);
                    setMensagem({
                        mensagem: error.response.data.error.message,
                        type: 'danger'
                    });
                });
            }
            }
            validationSchema={ouvidoriaFormSchema}
        >
                {({
                      values, errors, touched,
                      handleSubmit, setFieldValue, handleChange, setErrors
                  }) =>
                    (
                    <form
                        name="formCursoNovo"
                        method={'GET'}
                        onSubmit={handleSubmit}
                    >
                        <Card p={3} >
                            {mensagem && mensagem.type &&
                                <Message hasCloseButton={false} category='message' messageTitle={mensagem.mensagem}
                                         type={mensagem.type}/>
                            }
                            <Row>
                                <Col sm={12} md={12} lg={12} mt="3" className={'identificacao'}>
                                    <Select
                                        id="identificacao"
                                        label="Identificação"
                                        value={values.identificacao}
                                        placeholder="Selecione"
                                        onChange={(value) => {
                                            setFieldValue('identificacao', value);
                                        }}
                                        options={listaIdentificacao}
                                        status={errors.identificacao ? 'danger' : 'info'}
                                        feedbackText={errors.identificacao ? errors.identificacao : 'As informações abaixo ficarão disponíveis durante a tramitação da manifestação, permitindo maior agilidade na apuração.'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={6} lg={6} mt="3">
                                    <Select
                                        id="tipo"
                                        label="Tipo"
                                        value={values.tipo}
                                        placeholder="Selecione"
                                        onChange={(value) => {
                                            setFieldValue('tipo', value);
                                        }}
                                        options={listaTipo}
                                        status={errors.tipo ? 'danger' : undefined}
                                        feedbackText={errors.tipo ? errors.tipo : ''}
                                    />
                                </Col>
                                <Col sm={12} md={12} lg={12} mt="3">
                                    <Input
                                        id="nome"
                                        placeholder="Informe seu nome..."
                                        label="Nome Completo"
                                        value={values.nome}
                                        onChange={handleChange}
                                        status={errors.nome ? 'danger' : undefined}
                                        feedbackText={errors.nome ? errors.nome : ''}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={6} mt="3">
                                    <Input
                                        id="email"
                                        placeholder="E-mail"
                                        label="Informe seu e-mail..."
                                        value={values.email}
                                        onChange={handleChange}
                                        status={errors.email ? 'danger' : undefined}
                                        feedbackText={errors.email ? errors.email : ''}
                                    />
                                </Col>
                                <Col sm={12} md={3} lg={3} mt="3">
                                    <Input
                                        id="cpf"
                                        placeholder="Informe seu CPF..."
                                        label="CPF"
                                        value={values.cpf}
                                        onChange={handleChange}
                                        status={errors.cpf ? 'danger' : undefined}
                                        feedbackText={errors.cpf ? errors.cpf : ''}
                                    />
                                </Col>
                                <Col sm={12} md={3} lg={3} mt="3">
                                    <Input
                                        id="rg"
                                        placeholder="Informe seu RG..."
                                        label="RG"
                                        value={values.rg}
                                        onChange={handleChange}
                                        status={errors.rg ? 'danger' : undefined}
                                        feedbackText={errors.rg ? errors.rg : ''}
                                    />
                                </Col>
                                <Col sm={12} md={4} lg={4} mt="3">
                                    <Input
                                        id="telefone"
                                        placeholder="Informe seu Telefone..."
                                        label="Telefone"
                                        value={values.telefone}
                                        onChange={handleChange}
                                        status={errors.telefone ? 'danger' : undefined}
                                        feedbackText={errors.telefone ? errors.telefone : ''}
                                    />
                                </Col>
                                <Col sm={12} md={4} lg={4} mt="3">
                                    <Input
                                        id="cep"
                                        placeholder="Informe seu CEP..."
                                        label="CEP"
                                        value={values.cep}
                                        onChange={handleChange}
                                        status={errors.cep ? 'danger' : undefined}
                                        feedbackText={errors.cep ? errors.cep : ''}
                                    />
                                </Col>
                                <Col sm={12} md={4} lg={4} mt="3">
                                    <Input
                                        id="cidade"
                                        placeholder="Informe sua Cidade..."
                                        label="Cidade"
                                        value={values.cidade}
                                        onChange={handleChange}
                                        status={errors.cidade ? 'danger' : undefined}
                                        feedbackText={errors.cidade ? errors.cidade : ''}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={6} mt="3">
                                    <Input
                                        id="bairro"
                                        placeholder="Informe seu Bairro..."
                                        label="Bairro"
                                        value={values.bairro}
                                        onChange={handleChange}
                                        status={errors.bairro ? 'danger' : undefined}
                                        feedbackText={errors.bairro ? errors.bairro : ''}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={6} mt="3">
                                    <Input
                                        id="endereco"
                                        placeholder="Informe seu Endereço..."
                                        label="Endereço"
                                        value={values.endereco}
                                        onChange={handleChange}
                                        status={errors.endereco ? 'danger' : undefined}
                                        feedbackText={errors.endereco ? errors.endereco : ''}
                                    />
                                </Col>
                                <Col sm={12} md={12} lg={12} mt="3">
                                    <Textarea label={'Solicitação'}
                                              id={'solicitacao'}
                                               maxLength={1000}
                                              onChange={handleChange}
                                              status={errors.solicitacao ? 'danger' : undefined}
                                              feedbackText={errors.solicitacao ? errors.solicitacao : ''}> </Textarea>
                                </Col>
                                <Col sm={12} md={12} lg={12} mt="3">
                                    <Upload label={'Anexos'} id={'arquivo'}></Upload>
                                </Col>
                                <Col sm={12} md={12} lg={12} mt="5">
                                    <Button primary className={'align-left'}> Enviar</Button>
                                </Col>
                            </Row>
                        </Card>
                    </form>
                    )}
        </Formik>
)
}

export default FormularioCurso;
